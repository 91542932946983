<template>
  <div class="accountSet">
    <div class="cell">
      <div class="title">
        账户设定
        <div class="describe">更新您的基本账户信息</div>
      </div>
      <div class="content">
        <template v-for="(item, key, index) in data">
          <div class="item" :key="index">
            <div class="icon">
              <i :class="item.icon"></i>
            </div>
            <div class="text">
              <div class="one">
                <div class="t-left">
                  <div style="font-size: 18px; width: 20%; margin-right: 40px">
                    {{ item.label }}
                  </div>
                  <div
                 
                    style="display: flex; align-items: center; font-size: 14px"
                    :style="{ color: index == 2 ? '#999999' : '' }"
                  >
                    <el-avatar
                      size="medium"
                      :src="item.tx"
                      v-if="index == 0"
                      style="margin-right: 10px"
                    ></el-avatar>
                    {{
                      index == 2
                        ? "我们建议您定期更改密码以提高安全性。"
                        : item.value
                    }}
                  </div>
                </div>
                <div class="t-right">
                  <el-button
                    size="medium"
                    @click="handleOpenPop"
                    plain
                    v-if="index == 2"
                    >更新</el-button
                  >
                  <el-button size="medium" @click="handleOpen" plain v-else-if="index==1">{{
                    show ? "取消" : "编辑"
                  }}</el-button>
                </div>
              </div>
              
              <div class="ipt" v-if="index == 1 && show">
                <div class="demo-input-suffix">
                  <div style="margin-top: 10px">
                    {{ item.title }}
                  </div>
                  <div>
                    <el-input
                      style="width: 300px; margin-left: 10px"
                      placeholder="请输入内容"
                      v-model="item.data"
                    >
                    </el-input>
                    <!-- <div
                      v-if="index == 0"
                      style="
                        font-size: 14px;
                        padding: 10px;
                        padding-bottom: 2px;
                        color: #999999;
                      "
                    >
                      用户名必须在5到30个字符内，并且只能使用英文字母/数字/下划线/点，不可以含有空格或使用纯数字
                      ，用户账户只限修改一次，请谨慎操作！
                    </div> -->
                    <!-- <div
                    v-if="index == 0"
                    style="color: red; font-size: 12px; margin-left: 10px"
                  >
                    注：用户账户只限修改一次，请谨慎操作！
                  </div> -->
                    <div style="margin-top: 20px; padding-left: 10px">
                      <el-button
                        :disabled="!item.data"
                        type="primary"
                        size="medium"
                        @click="edit(item.type)"
                        >储存</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <el-dialog
      title="创建登入密码"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div>
        <el-form
          ref="form"
          label-position="top"
          label-width="80px"
          :rules="rules"
          :model="formLabelAlign"
        >
          <el-form-item
            prop="pwd"
            label="现在的密码"
            style="margin-bottom: 10px"
          >
            <el-input v-model="formLabelAlign.pwd"></el-input>
          </el-form-item>
          <el-form-item
            prop="new_pwd"
            label="新密码"
            style="margin-bottom: 10px"
          >
            <el-input v-model="formLabelAlign.new_pwd"></el-input>
          </el-form-item>
          <el-form-item
            prop="confirm_pwd"
            label="确认密码"
            style="margin-bottom: 10px"
          >
            <el-input v-model="formLabelAlign.confirm_pwd"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { shopInfo, setAccount, changePassword } from "@/api/shop.js";
import env from "@/settings/env.js";
export default {
  data() {
    return {
      baseUrl: env.fileUrlPrefix,
      data: {
        my: {
          label: "我的档案",
          icon: "el-icon-user",
          value: "",
          tx: "",
          title: "用户账号",
          data: "",
          type: 1,
        },
        phone: {
          label: "电话",
          icon: "el-icon-phone-outline",
          value: "",
          title: "手机号码",
          data: "",
          type: 2,
        },
        // email: {
        //   label: "电邮",
        //   icon: "el-icon-user",
        //   value: "",
        // },
        passwprd: {
          label: "登入密码",
          icon: "el-icon-lock",
          value: "",
        },
      },
      show: false,
      formLabelAlign: {
        pwd: "",
        new_pwd: "",
        confirm_pwd: "",
      },
      rules: {
        pwd: [
          { trigger: "blur", message: "必填", required: true },
          { trigger: "blur", min: 8, message: "密码不能小于8位" },
        ],
        new_pwd: [
          { trigger: "blur", message: "必填", required: true },
          { trigger: "blur", min: 8, message: "密码不能小于8位" },
        ],
        confirm_pwd: [
          { trigger: "blur", message: "必填", required: true },
          { trigger: "blur", min: 8, message: "密码不能小于8位" },
        ],
      },
      dialogVisible: false,
      type: 1,
    };
  },
  created() {
    this.getShopInfo();
    this.type = localStorage.SHOPELOGINTYPE;
    // 登录类型为2的时候隐藏掉登入密码的修改
    if (this.type == 2) {
      delete this.data.passwprd;
    }
  },
  methods: {
    getShopInfo() {
      let info = this.$store.state.shop_info;
      shopInfo().then((res) => {
        if (res.code == 1) {
          this.data.my.tx = res.data.shop_avatar;
          this.data.my.value = info.name;
          this.data.phone.value = info.mobile;
          // this.data.email.value = info.email;
        }
      });
    },
    handleOpen() {
      this.data.my.data = "";
      this.data.phone.data = "";
      this.show = this.show ? false : true;
    },
    handleOpenPop() {
      this.dialogVisible = true;
    },
    edit(type) {
      let data = {
        type: type,
        content: type == 1 ? this.data.my.data : this.data.phone.data,
      };
      setAccount(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          if (type == 1) {
            this.data.my.value = this.data.my.data;
          }
          if (type == 2) {
            this.data.phone.value = this.data.phone.data;
          }
        }
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          changePassword(this.formLabelAlign).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.message);
              this.handleClose();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.accountSet {
  .cell {
    padding: 30px 0;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.6);
    .title {
      padding: 0 30px;
      font-size: 18px;
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 30px;
      .describe {
        font-size: 14px;
        color: #999;
        margin-top: 10px;
      }
    }
    .content {
      width: 100%;
      padding: 0 20px;
      .item {
        display: flex;
        // align-items: center;
        width: 100%;
        // padding: 20px 0;
        .icon {
          width: 3%;
          text-align: center;
          font-size: 26px;
          color: #a5a5a5;
          margin-top: 20px;
        }
        .text {
          padding: 20px 0;
          width: 96%;
          margin-left: 20px;
          border-bottom: 1px solid #e8e8e8;
          .one {
            display: flex;
            align-items: center;
            .t-left {
              width: 100%;
              display: flex;
              // justify-content: space-between;
              align-items: center;
              // margin-left: 20px;
            }
            .t-right {
              // width: 6%;
              justify-content: right;
              // padding-right: 20px;
            }
          }
          .ipt {
            padding: 20px 80px;
            background: #f6f6f6;
            margin-right: 20px;
            margin-top: 20px;
            .demo-input-suffix {
              display: flex;
            }
          }
        }
      }
    }
  }
}
</style>